<section style="margin-top: 60px">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-lg-4 col-xl-3 mb-4">
        <app-filter></app-filter>
      </div>
      <div class="col-md-12 col-lg-7 col-xl-9">
        <div class="d-sm-flex">
          <div class="result-count me-3 mb-2 col-sm-3">
            <div class="d-flex">
              {{ totalCount }} <span>Savanna<br />Items</span
              ><img
                src="assets/images/item-heart.svg"
                class="ms-2"
                width="25"
              />
            </div>
          </div>
          <div class="me-3 mb-3" style="min-width: 300px">
            <div class="btn-search cursor-pointer" (click)="open(search, 'xl')">
              <img
                src="assets/images/search-icon.svg"
                width="20"
                style="margin-right: 15px"
              /># Text
            </div>

            <!-- Search modal -->
            <ng-template #search let-modal>
              <div class="detail-modal">
                <div class="modal-body">
                  <div class="row">
                    <div class="col-sm-8 mx-auto">
                      <button
                        type="button"
                        class="close"
                        aria-label="Close"
                        (click)="modal.dismiss('Cross click')"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                      <div class="text-center" style="padding: 40px 30px">
                        <!-- <div
                          style="color: #fff"
                          class="detail-header ms-0 text-center"
                          id="modal-basic-title"
                        >
                          Name your saved search
                        </div> -->
                        <p style="margin-bottom: 20px">
                          Give your search a name
                        </p>
                        <div class="row col-lg-12 mx-auto">
                          <div class="col-lg-2 mb-2 my-auto">
                            <p>Name</p>
                          </div>
                          <div class="col-lg-7 mb-2 my-auto">
                            <input
                              class="form-control search-input"
                              type="search"
                              placeholder="Search items, collections, and accounts"
                              aria-label="Search"
                              #serachKey
                            />
                          </div>
                          <div class="col-lg-3 mb-2 my-auto">
                            <div
                              class="btn-save mb-0"
                              (click)="searchNft(serachKey.value)"
                            >
                              Search
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>

          <div class="me-3 mb-2 ms-sm-auto">
            <select
              class="select-option"
              aria-label="Default select example"
              #sort
              (change)="sortBy(sort.value)"
            >
              <option value="1" selected>Recently Listed</option>
              <option value="2">Recently Created</option>
              <option value="3">Price: Low to High</option>
              <option value="4">Price: High to Low</option>
            </select>
          </div>
          <div class="me-3 mb-2 d-flex">
            <div (click)="gridRow(1)" class="view-btn me-2">
              <fa-icon [icon]="faTh" class="grid-row"></fa-icon>
            </div>
            <div (click)="gridRow(0)" class="view-btn">
              <fa-icon [icon]="faList" class="grid-row"></fa-icon>
            </div>
          </div>
        </div>

        <div class="row">
          <ng-container *ngIf="!nftList">
            <div>Loading ...</div>
          </ng-container>
          <ng-container *ngIf="nftList && nftList.length == 0">
            <div class="text-center">NFT is not available.</div>
          </ng-container>
          <div *ngIf="isGrid; then grid; else row"></div>
          <ng-template #grid>
            <div
              class="col-10 col-sm-4 col-lg-6 col-xl-4 margin-auto px-2 mb-3"
              *ngFor="let nft of nftList"
              infiniteScroll
              [infiniteScrollDistance]="4"
              [infiniteScrollThrottle]="300"
              (scrolled)="onScroll()"
            >
              <div class="product-bg">
                <div class="product-wrapper">
                  <a
                    href="product/detail/{{ nft?.tokenId }}?token_address={{
                      nft.token_address
                    }}&blockchain={{ nft.blockchain }}"
                    class="product-detail-link"
                  >
                    <div class="row mb-3">
                      <div class="col-12">
                        <div class="row">
                          <div class="col-8">
                            <div class="name-label">
                              {{
                                nft.contract_address
                                  ? nft.animal_name
                                  : nft.attributes[2].value
                              }}
                            </div>
                          </div>
                          <div class="col-4 mt-auto">
                            <div class="id-label"># {{ this.nft.tokenId }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-6 my-auto h-100">
                        <div
                          class="nft-image"
                          *ngIf="nft.file_type == 'mp4'; else image"
                        >
                          <video
                            style="object-fit: cover; border-radius: 7.5px"
                            width="100%"
                          >
                            <source
                              [src]="nft?.media_uri"
                              type="video/mp4"
                              preload="none"
                            />
                          </video>
                        </div>
                        <ng-template #image>
                          <div class="nft-image">
                            <img [src]="nft?.media_uri" />
                          </div>
                        </ng-template>
                      </div>

                      <div
                        class="col-6 ps-sm-0 my-auto"
                        *ngIf="nft.contract_address"
                      >
                        <div class="row mx-0">
                          <div class="col-12 px-1">
                            <p class="product-lv">
                              Lv:
                              {{
                                nft.animal_attributes
                                  ? nft.animal_attributes[0].value
                                  : "0"
                              }}
                            </p>
                          </div>
                        </div>
                        <div
                          class="row mx-0"
                          *ngIf="
                            nft?.animal_attributes[0].display_type;
                            else genesis
                          "
                        >
                          <div
                            class="col-4 p-1"
                            *ngFor="
                              let attribute of nft?.animal_attributes.slice(3)
                            "
                          >
                            <img
                              src="assets/images/{{
                                attribute?.display_type + '.png'
                              }}"
                              class="heart-img"
                            />
                            <ng-template #noDisplayType
                              ><img
                                src="assets/images/heart-life.png"
                                class="heart-img"
                            /></ng-template>
                          </div>
                        </div>
                        <ng-template #genesis>
                          <div class="row mx-0">
                            <div
                              class="col-4 p-1"
                              *ngFor="let attribute of nft?.extra_attributes"
                            >
                              <img
                                *ngIf="
                                  attribute?.trait_type !== 'Regenerate HP/s' &&
                                  attribute?.value.includes('%')
                                "
                                src="assets/icon/{{
                                  attribute?.trait_type + '_boost_percentage'
                                }}.png"
                                class="heart-img"
                              />
                              <img
                                *ngIf="
                                  attribute?.trait_type !== 'Regenerate HP/s' &&
                                  !attribute?.value.includes('%')
                                "
                                src="assets/icon/{{
                                  attribute?.trait_type + '_boost_number'
                                }}.png"
                                class="heart-img"
                              />
                              <img
                                *ngIf="
                                  attribute?.trait_type == 'Regenerate HP/s'
                                "
                                src="assets/icon/Regenerate HPs_boost_percentage.png"
                                class="heart-img"
                              />
                            </div>
                          </div>
                        </ng-template>
                      </div>

                      <div
                        class="col-6 ps-sm-0 my-auto"
                        *ngIf="!nft.contract_address"
                      >
                        <div class="row mx-0">
                          <div class="col-12 px-1">
                            <p class="product-lv">
                              Lv:
                              {{
                                nft.attributes ? nft.attributes[0].value : "0"
                              }}
                            </p>
                          </div>
                        </div>
                        <div class="row mx-0">
                          <div
                            class="col-4 p-1"
                            *ngFor="let attribute of nft?.attributes.slice(3)"
                          >
                            <img
                              *ngIf="
                                attribute?.trait_type !== 'Regenerate HP/s'
                              "
                              src="assets/icon/{{
                                attribute?.trait_type +
                                  '_' +
                                  attribute?.display_type
                              }}.png"
                              class="heart-img"
                            />
                            <img
                              *ngIf="attribute?.trait_type == 'Regenerate HP/s'"
                              src="assets/icon/Regenerate HPs_boost_percentage.png"
                              class="heart-img"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-12 mt-3">
                        <div class="row">
                          <div class="col-12">
                            <div class="btn-price">
                              <img
                                src="assets/images/price-unit.svg"
                                width="20px"
                                class="me-3"
                              />
                              <p *ngIf="nft?.price > 0 && nft?.isListed">
                                {{ nft?.price }}
                                <span>polygon </span>
                              </p>
                              <p *ngIf="nft?.price == 0 || !nft.isListed">
                                View Info
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template #row>
            <div
              class="col-lg-6"
              *ngFor="let nft of nftList"
              style="margin-bottom: 20px"
            >
              <div class="product-row-bg">
                <div class="product-wrapper">
                  <a
                    href="product/detail/{{ nft?.tokenId }}?token_address={{
                      nft.token_address
                    }}&blockchain={{ nft.blockchain }}"
                    class="product-detail-link"
                  >
                    <div class="row mb-3">
                      <div class="col-8">
                        <div class="name-label">
                          {{
                            nft.contract_address
                              ? nft.animal_name
                              : nft.attributes[2].value
                          }}
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="id-label"># {{ this.nft.tokenId }}</div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-5 col-sm-6 my-auto">
                        <div
                          class="nft-image"
                          *ngIf="nft.file_type == 'mp4'; else image"
                        >
                          <video
                            style="object-fit: cover; border-radius: 7.5px"
                            width="100%"
                          >
                            <source
                              [src]="nft?.media_uri"
                              type="video/mp4"
                              preload="none"
                            />
                          </video>
                        </div>
                        <ng-template #image>
                          <div class="nft-image">
                            <img [src]="nft?.media_uri" />
                          </div>
                        </ng-template>
                      </div>
                      <!-- animal -->
                      <div
                        class="col-7 col-sm-6 my-auto"
                        *ngIf="nft.contract_address"
                      >
                        <div class="row">
                          <div class="col-10 col-sm-9 mx-auto">
                            <div class="row">
                              <div class="col-12 p-1">
                                <p class="product-lv">
                                  Lv:
                                  {{
                                    nft.animal_attributes
                                      ? nft.animal_attributes[0].value
                                      : "0"
                                  }}
                                </p>
                              </div>
                            </div>
                            <div class="row">
                              <div
                                class="col-12 my-auto"
                                *ngIf="nft.contract_address"
                              >
                                <div
                                  class="row mx-0"
                                  *ngIf="
                                    nft?.animal_attributes[0].display_type;
                                    else genesis
                                  "
                                >
                                  <div
                                    class="col-4 p-1"
                                    *ngFor="
                                      let attribute of nft?.animal_attributes.slice(
                                        3
                                      )
                                    "
                                  >
                                    <img
                                      src="assets/images/{{
                                        attribute?.display_type + '.png'
                                      }}"
                                      class="heart-img"
                                    />
                                    <ng-template #noDisplayType
                                      ><img
                                        src="assets/images/heart-life.png"
                                        class="heart-img"
                                    /></ng-template>
                                  </div>
                                </div>
                                <ng-template #genesis>
                                  <div class="row mx-0">
                                    <div
                                      class="col-4 p-1"
                                      *ngFor="
                                        let attribute of nft?.extra_attributes
                                      "
                                    >
                                      <img
                                        *ngIf="
                                          attribute?.trait_type !==
                                            'Regenerate HP/s' &&
                                          attribute?.value.includes('%')
                                        "
                                        src="assets/icon/{{
                                          attribute?.trait_type +
                                            '_boost_percentage'
                                        }}.png"
                                        class="heart-img"
                                      />
                                      <img
                                        *ngIf="
                                          attribute?.trait_type !==
                                            'Regenerate HP/s' &&
                                          !attribute?.value.includes('%')
                                        "
                                        src="assets/icon/{{
                                          attribute?.trait_type +
                                            '_boost_number'
                                        }}.png"
                                        class="heart-img"
                                      />
                                      <img
                                        *ngIf="
                                          attribute?.trait_type ==
                                          'Regenerate HP/s'
                                        "
                                        src="assets/icon/Regenerate HPs_boost_percentage.png"
                                        class="heart-img"
                                      />
                                    </div>
                                  </div>
                                </ng-template>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- equipment -->
                      <div
                        class="col-7 col-sm-6 my-auto"
                        *ngIf="!nft.contract_address"
                      >
                        <div class="row">
                          <div class="col-10 col-sm-9 mx-auto">
                            <div class="row">
                              <div class="col-12 p-1">
                                <p class="product-lv">
                                  Lv:
                                  {{
                                    nft.attributes
                                      ? nft.attributes[0].value
                                      : "0"
                                  }}
                                </p>
                              </div>
                            </div>
                            <div class="row mx-0">
                              <div
                                class="col-4 p-1"
                                *ngFor="
                                  let attribute of nft?.attributes.slice(3)
                                "
                              >
                                <img
                                  *ngIf="
                                    attribute?.trait_type !== 'Regenerate HP/s'
                                  "
                                  src="assets/icon/{{
                                    attribute?.trait_type +
                                      '_' +
                                      attribute?.display_type
                                  }}.png"
                                  class="heart-img"
                                />
                                <img
                                  *ngIf="
                                    attribute?.trait_type == 'Regenerate HP/s'
                                  "
                                  src="assets/icon/Regenerate HPs_boost_percentage.png"
                                  class="heart-img"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mt-2">
                      <div class="col-12">
                        <div class="btn-price">
                          <img
                            src="assets/images/price-unit.svg"
                            width="20px"
                            class="me-3"
                          />
                          <p *ngIf="nft?.price > 0">
                            {{ nft?.price }}
                            <span>polygon </span>
                          </p>
                          <p *ngIf="nft?.price == 0">View Info</p>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div></ng-template
          >
        </div>
      </div>
    </div>
  </div>
</section>
