<section style="padding:  30px; min-height: calc(100vh - 50px ); background: url(assets/images/community-bg.png)no-repeat; margin-top:50px; background-size: cover ;">
	<div class="container">
		<div class="row">
			<div class="col-lg-4 mx-auto text-center">
				<h2>SavannaSurvival <br/>Community </h2>
				<p style="margin-bottom: 20px">Explore the guide of Savanna world by click & follow us on social media channels</p>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-4 text-center">
				<div class="community-social">
					<img src="assets/images/facebook-icon.svg" width="100px">
					<a href="#" target="_blank" class="btn-community">Facebook</a>
				</div>
			</div>
			<div class="col-lg-4 text-center">
				<div class="community-social">
					<img src="assets/images/twitter-icon.svg" width="100px">
					<a href="#" target="_blank" class="btn-community">Twitter</a>
				</div>
			</div>
			<div class="col-lg-4 text-center">
				<div class="community-social">
					<img src="assets/images/medium-icon.svg" width="100px">
					<a href="#" target="_blank" class="btn-community">Medium</a>
				</div>
			</div>
			<div class="col-lg-4 ms-auto text-center">
				<div class="community-social">
					<img src="assets/images/discord-icon.svg" width="100px">
					<a href="#" target="_blank" class="btn-community">Discord</a>
				</div>
			</div>
			<div class="col-lg-4 me-auto text-center">
				<div class="community-social">
					<img src="assets/images/telegram-icon.svg" width="100px">
					<a href="#" target="_blank" class="btn-community">Telegram</a>
				</div>
			</div>
		</div>
		<div class="row" style="margin-top: 80px">
			<div class="col-lg-10 mx-auto">
				<div class="row">
					<div class="col-lg-3 text-center">
						<p style="color: #FFB100">©2022 Canverse Asia</p>
					</div>
					<div class="col-lg-3 text-center">
						<a href="faq/privacy-policy">Privacy Policy</a>
					</div>
					<div class="col-lg-3 text-center">
						<a href="faq/terms-of-use">Term of Use</a>
					</div>
					<div class="col-lg-3 text-center">
						<a href="mailto:play@SavannaSurvival.io">play@SavannaSurvival.io</a>
					</div>
				</div>
			</div>
		</div>
	</div>

</section>

