<section style="padding:  30px; min-height: 100vh;  margin-top:90px;">
	<div class="container">
		<div class="row">
			<div class="col-lg-10 mx-auto">
				<h1>Privacy Policy</h1>
			</div>
		</div>
		<div class="row">
			<div class="col-lg-10 mx-auto">
				<h2>Privacy & Cookie Policy</h2>
				<p class="mb-4">At <strong>SURVIVALVERSE</strong> we care about your privacy and that’s why we provide full transparency into how and why we collect data and your choices on what personal information you share with us. Privacy protection is something that <strong>SURVIVALVERSE</strong> is fully committed to.</p>

				<div class="mb-4">
					<h3>Who we are</h3>
					<p>Our website address is www.savannasurvival.io This website contains information pertaining to SURVIVALVERSE. The information below sets out how SURVIVALVERSE uses and protects the data collected via our website. When using this site, you are legally bound to the following Privacy & Cookie Policy, so please read it carefully.</p>
				</div>

				<div class="mb-4">
					<h2>The information we collect</h2>
					<h3>A. Personally Identifiable Information</h3>
					<p>This website does not collect personally identifiable information about you, such as your name, physical address, and email address, unless you voluntarily provide us this information to subscribe to our electronic newsletters, request more information from us, or for other purposes. We may add the information you voluntarily provide to us to our databases, subscriber lists, and contact lists, as well as those of our subsidiaries. As discussed in more detail below, we may also associate the information provided by cookies with personally iden8fiable information you have voluntarily provided to us.</p>
				</div>
				
				<div class="mb-4">
					<h3>B. Non-Personally Identifiable Information</h3>
					<p>This Site automatically collects non-personally identifiable information from you when you use this website. This information is collected through cookies and may include, without limitation, the following : your internet service provider, the type of browser and operating system that you use, and information about your Site visit, including date and time, length of stay, pages viewed, the website address, if any, from which you linked directly to this website, the website address, if any, to which you travel from this website, and other traffic information. We use this non-personally identifiable information to help us improve this website and the services we provide through it. We may do this by aggregating your non- personally identifiable information with similar information collected from other users.</p>
				</div>

				<div class="mb-4">
					<h3>C. IP Address</h3>
					<p>We also use cookies to collect the IP (Internet Protocol) address of your computer automatically when you visit this website. We do not use your IP address to identify you personally, except that the cookies discussed below may use your IP address to associate Site usage information with personally identifiable information you have voluntarily given to us. You can restrict or block our use of cookies to collect your IP address and for other purposes as discussed below.</p>
				</div>
				
				<div class="mb-4">
					<h3>How we use the information</h3>
					<p>All of the information collected from the website is retained by SURVIVALVERSE. We will never divulge any information to third parties outside of the company without prior consent from the website visitor.</p>
				</div>
				
				<div class="mb-4">
					<h3>What do we do with the information</h3>
					<p>In order to understand your needs and provide you with a better service, we will require the aforementioned information.</p>
					<p>We may use the information for the following reasons :</p>
					<ul>
						<li>internal record keeping</li>
						<li>the improvement of our products and services</li>
						<li>with your consent, we may share your email address with third parties for targeted advertising, and/or to inform you of the latest events, offers, and news from <strong>SURVIVALVERSE</strong></li>
						<li>to customise the website according to your interests</li>
					</ul>
				</div>

				<div class="mb-4">
					<h2>Security</h2>
					<p><strong>SURVIVALVERSE</strong> is committed to ensuring that your personal information is secure. We follow strict policies and adhere to high standards of security when gathering personal information.</p>
				</div>

				<div class="mb-4">
					<h3>Embedded content from other websites</h3>
					<p>Articles on this site may include embedded content (e.g. videos, images, articles, etc.). Embedded content from other websites behaves in the exact same way as if the visitor has visited the other website. These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content, including tracking your interaction with the embedded content if you have an account and are logged in to that website.Articles on this site may include embedded content (e.g. videos, images, articles, etc.). Embedded content from other websites behaves in the exact same way as if the visitor has visited the other website. These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content, including tracking your interaction with the embedded content if you have an account and are logged in to that website.</p>
				</div>

				<div class="mb-4">
					<h3>Cookies</h3>
					<p>This website uses cookies, which are small files sent from the Site to your browser or your computer, tablet, or mobile phone (“device”) and then stored on your device’s hard drive. A cookie records on your device information relating to your internet activity, such as whether you have visited the Site before. We use the cookies to support Site functionality. We may also use them to collect statistical information that we analyze for insights on Site usage. You have the choice of whether or not to accept cookies. For your information, the majority of web browsers accept cookies. You can decline cookies within your web browser settngs if necessary. However, doing so will not give you full functionality of the website. If you accept cookies, we are able to monitor which pages our visitors find useful. This provides us with the information to create a better user experience. We do not have access to your computer or any other information about you through the use of cookies.</p>
				</div>

				<div class="mb-4">
					<h3>You can use your browser’s settings to restrict or block the cookies sent by this website.</h3>
					<h3>This would not prevent you from using the website.</h3>
				</div>

				<div class="mb-4">
					<h3>How do we use cookies?</h3>
					<p>We may use the following cookies to collect statistical information on Site usage:</p>
					<p><strong>Google Analytics :</strong> This cookie allows us to see information on user website ac8vi8es including, but not limited to page views, source and time spent on website. The information is depersonalise and is displayed as numbers, meaning it cannot be tracked back to individuals. This will help to protect your privacy. By using Google Analytics we can see what content is popular on our website and strive to give you more of the things you enjoy reading and watching. You can opt-out of Google’s use of cookies by visiting Google’s Ads Settings here.</p>
					<p>Social sharing cookies : We may use social sharing buttons for services including, but not limited to, Twitter, LinkedIn, Telegram, Discord and Facebook.</p>
					<p>These allow you to share pages with your friends via those networks. Whilst you will be asked to login to use these services, this process is handled directly with the social sharing providers and their databases and servers. These sites may also monitor your usage of these buttons, through the use of external tracking codes that are required to run the buttons on this website.</p>
				</div>
				
				<div class="mb-4">
					<h3>Links to other websites</h3>
					<p>This website may contain links to other websites of interest. However, once you have used these links to leave our site, you should note that we do not have any control over the other websites.</p>
					<p>Therefore, we cannot be responsible for the protection and privacy of any information which you provide whilst visiting such sites and such sites are not governed by this privacy statement.</p>
					<p>You should exercise caution and look at the privacy statement applicable to the website in question.</p>
				</div>

				<div class="mb-4">
					<h3>Controlling your personal information</h3>
					<p>You may choose to restrict the collection or use of your personal information, if you have previously agreed to us using your personal information for direct marketing purposes, you may opt-out at any time by wri8ng to or emailing us via Contact Us on the website. We will not sell, distribute or lease your personal information to third par8es unless we have your permission or are required by law to do so.</p>
				</div>

				<div class="mb-4">
					<h3>Variations to the Policy</h3>
					<p>We reserve the right to alter and change information provided in this document at our own discretion and therefore this document should be regularly referred to for updates and variations. If you have any questions about this Privacy & Cookie Policy or our collection and use of your personally identifiable information, please contact us at play@survivalverse.io</p>
				</div>
				<div style="color: #FFB100" class="mb-4"><strong>© 2022, SURVIVALVERSE.</strong> Privacy & Cookie Policy. This site is protected by reCAPTCHA. The Google Privacy Policy and Terms of Service apply.</div>
			</div>
		</div>
		<div class="row" style="margin-top: 60px">
			<div class="col-lg-7 mx-auto">
				<div class="row">
					<div class="col-lg-3 text-center">
						<p>©2022 Canverse Asia</p>
					</div>
					<div class="col-lg-3 text-center">
						<a href="faq/privacy-policy">Privacy Policy</a>
					</div>
					<div class="col-lg-3 text-center">
						<a href="faq/term-of-use">Term of Use</a>
					</div>
					<div class="col-lg-3 text-center">
						<a href="#">play@SavannaSurvival.io</a>
					</div>
				</div>
			</div>
		</div>
	</div>

</section>

