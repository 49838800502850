<div class="mt-2 mb-4 mx-auto" aria-label="pagination" style="display: flex;  justify-content: center;">
  <ul class="pagination">
	<li class="page-item d-sm-none"><span class="page-link page-link-static">{{ page + 1 }} / {{ maxPage }}</span></li>
	<li class="page-item" style="width: 30px;">
		<a class="page-link" (click)="changePage(page - 1)" href="javascript:void(0);" aria-label="Previous" [style.visibility]="0 < page ? 'visible' : 'hidden'">
			<
		</a>
	</li>
	<li class="page-item d-gone d-sm-block" [style.visibility]="0 < page - 2 ? 'visible' : 'hidden'">
		<a class="page-link" (click)="changePage(0)" href="javascript:void(0);">{{ 1 }}</a>
	</li>
	<li class="page-item d-none d-sm-block" [style.visibility]="0 < page - 2 ? 'visible' : 'hidden'">...</li>
	<li class="page-item d-none d-sm-block" [style.visibility]="0 < page - 1 ? 'visible' : 'hidden'">
		<a class="page-link" (click)="changePage(page - 2)" href="javascript:void(0);">{{ page - 1 }}</a>
	</li>
	<li class="page-item d-none d-sm-block"[style.visibility]="0 < page ? 'visible' : 'hidden'">
		<a (click)="changePage(page - 1)" href="javascript:void(0);" class="page-link">{{ page }}</a>
	</li>
	<li class="page-item active d-none d-sm-block" aria-current="page">
		<span class="page-link">{{ page + 1 }}<span class="visually-hidden">(current)</span></span>
	</li>
	<li class="page-item d-none d-sm-block" [style.visibility]="maxPage > page + 1 ? 'visible' : 'hidden'">
		<a (click)="changePage(page + 1)" href="javascript:void(0);" class="page-link">{{ page + 2 }}</a>
	</li>
	<li class="page-item d-none d-sm-block" [style.visibility]="maxPage > page + 2 ? 'visible' : 'hidden'">
		<a (click)="changePage(page + 2)" class="page-link" href="javascript:void(0);">{{ page + 3 }}</a>
	</li>
	<li class="page-item d-none d-sm-block" [style.visibility]="maxPage > page + 3 ? 'visible' : 'hidden'">...</li>
	<li class="page-item d-none d-sm-block" [style.visibility]="maxPage > page + 3 ? 'visible' : 'hidden'">
		<a (click)="changePage(maxPage - 1)" class="page-link" href="javascript:void(0);">{{ maxPage }}</a>
	</li>
	<li class="page-item" style="width: 30px;">	
		<a class="page-link" (click)="changePage(page + 1)" href="javascript:void(0);" aria-label="Next "  [style.visibility]="maxPage -1 > page ? 'visible' : 'hidden'">
			>
		</a>
	</li>
  </ul>
</div>